import React, {useEffect, useState, useRef} from 'react';
import {useForm, SubmitHandler} from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import componentMap from './componentMap';

export const SplittyForms: React.FC<{ domElement: HTMLElement, formID: string }> = ({domElement, formID}) => {
    const [formConfig, setFormConfig] = useState<any | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const recaptchaRef = useRef<ReCAPTCHA | null>(null);
    const {register, handleSubmit, clearErrors, formState: {errors}} = useForm();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    useEffect(() => {
        if (formID) {
            fetch(`${import.meta.env.VITE_API_BASE_URL}/form/${formID}`)
                .then(response => response.json())
                .then(data => setFormConfig(data))
                .catch(error => console.error('Error fetching form configuration:', error));
        }
    }, [domElement, formID]);

    const submitForm: SubmitHandler<Record<string, any>> = async (data) => {
        clearErrors();
        setSuccessMessage(null);
        setIsSubmitting(true);

        if (formConfig.form_data) {
            let token = null;
            if (recaptchaRef.current && formConfig.form_data.sitekey) {
                token = await recaptchaRef.current.executeAsync();
            }

            const submissionData = {
                ...data,
                recaptcha_token: token,
                id: formConfig.uuid,
                origin: window.location.origin
            };

            try {
                const response = await fetch(import.meta.env.DEV ? 'http://192.168.0.17:8000/api/event/pr/wordpress' : formConfig.form_data.endpoint, {
                    method: formConfig.form_data.method,
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(submissionData),
                });
                const result = await response.json();


                if (result.status === 'success') {
                    setSuccessMessage(result.message);
                    if (formConfig.form_data.redirect === true) {
                        window.location.href = result.redirect_url;
                    }
                } else {
                    setErrorMessage(result.message);
                }


            } catch (error) {
                console.error('Error submitting form:', error);
                setErrorMessage('Failed to submit the form. Please try again.');
            } finally {
                // This will ensure that the submit button is re-enabled after submission
                recaptchaRef.current?.reset();
                setIsSubmitting(false);
            }
        }
    };

    if (!formConfig) {
        return <p>Loading form...</p>;
    }

    return (
        <section>
            <div id="splitty__form" className="form-container">
                {errorMessage && (
                    <div className="error-message">
                        <div className="alert alert-danger">
                            {errorMessage}
                        </div>
                    </div>
                )}
                {!successMessage && (
                    <form id={formConfig.form_data.id} onSubmit={handleSubmit(submitForm)}
                          className={`body ${formConfig.form_data.layout}`}>
                        {formConfig.form_data.fields.map((field: any, index: number) => {
                            const Component = componentMap[field.type]; // Dynamically pick component

                            if (!Component) {
                                console.error(`Component for type "${field.type}" not found.`);
                                return null;
                            }

                            return React.createElement(Component, {
                                key: index,
                                ...field,  // Spread the field attributes to the component
                                register,  // Pass register function for form components
                                validation: field.validation || {},  // Pass validation if needed
                                errors,  // Pass form errors
                                disabled: isSubmitting  // Pass disabled state for the SubmitButton
                            });
                        })}

                        {formConfig.form_data.sitekey && (
                            <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={formConfig.form_data.sitekey}/>
                        )}

                    </form>
                )}

                {successMessage && (
                    <div className="success-message">
                        <div className="alert alert-success">{successMessage}</div>
                    </div>
                )}
            </div>
        </section>
    );
};
