// embedForm.ts

// TypeScript typing for import statement
type RenderWidgetFunction = (elementId: string, formId: string) => void;

function renderForm(elementId: string, formId: string) {
    const container = document.getElementById(elementId);

    if (!container) {
        console.error(`Element with ID '${elementId}' not found.`);
        return;
    }

    // Dynamically load the React app that renders the form
    import('./main').then(({renderWidget}) => {
        const renderFormWidget: RenderWidgetFunction = renderWidget;
        renderFormWidget(elementId, formId);
    }).catch(err => console.error('Failed to load the form widget:', err));
}

// Automatically initialize the form for all elements with class "form-widget"
document.querySelectorAll('.splitty__form-embed').forEach(function (element) {
    const el = element as HTMLElement;
    const elementId = el.getAttribute('id');
    const formId = el.getAttribute('data-form-id');

    if (elementId && formId) {
        renderForm(elementId, formId);
    } else {
        console.error('Missing required data attributes: elementId or formId.');
    }
});
