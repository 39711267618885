import Input from './component/input';
import SubmitButton from './component/submitButton';

const componentMap: { [key: string]: React.FC<any> } = {
    input: Input,
    submitButton: SubmitButton,
    // Add more components here as needed
};

export default componentMap;
