import React from 'react';
import './submitButton.css';

type SubmitButtonProps = {
    text: string;         // The text to display on the button
    disabled?: boolean;   // Optional: whether the button is disabled
};

const SubmitButton: React.FC<SubmitButtonProps> = ({text, disabled = false}) => {
    return (
        <button type="submit" className="submit-button" disabled={disabled}>
            {disabled ? <div className="loader">
                <div></div>
                <div></div>
                <div></div>
            </div> : text}
        </button>
    );
};

export default SubmitButton;
