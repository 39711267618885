import React from 'react';
import {FieldErrors, UseFormRegister} from 'react-hook-form';

type InputProps = {
    name: string;
    type: string;
    label?: string;
    placeholder?: string;
    register: UseFormRegister<any>;
    validation?: object;
    errors: FieldErrors;
};

const Input: React.FC<InputProps> = ({name, type, label, placeholder, register, validation = {}, errors}) => {
    const errorMessage = errors[name]?.message; // Extract error message from react-hook-form errors

    return (
        <div className="input-group">
            {label && <label htmlFor={name}>{label}</label>}
            <input
                {...register(name, validation)}
                type={type}
                placeholder={placeholder}
                id={name}
                className="form-input"
            />
            {errorMessage && (
                <div className="error-message">
                    <div className="alert alert-danger">
                        {typeof errorMessage === 'string' ? errorMessage : 'Invalid input'}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Input;
