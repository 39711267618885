import React from 'react';
import ReactDOM from 'react-dom/client';
import {SplittyForms} from './SplittyForms';
import './index.css';


// Function to initialize and embed the widget in the DOM dynamically
function renderWidget(elementId: string, formId: string) {
    const container = document.getElementById(elementId);

    if (!container) {
        console.error(`Element with ID '${elementId}' not found.`);
        return;
    }

    const root = ReactDOM.createRoot(container);
    root.render(
        <React.StrictMode>
            <SplittyForms domElement={container} formID={formId}/>
        </React.StrictMode>
    );
}

// Export the render function for external use
export { renderWidget };

// If you're testing locally, you can call renderWidget here to mount the widget
// renderWidget('root', '1');
if (import.meta.env.DEV) {
    renderWidget('root', '2');
}
